import clsx from 'clsx';
import React from 'react';
import type { RadioInputProps } from './interfaces';
import styles from './RadioInput.module.scss';

const RadioInput = ({
  label,
  subLabel,
  color = 'default',
  inactive,
  ...inputProps
}: RadioInputProps) => {
  const className = clsx(styles.wrapper, styles[color]);
  return (
    <label className={className}>
      <input
        type="radio"
        {...inputProps}
        className={clsx(inputProps.className, {
          [styles.inactive]: inactive,
        })}
      />
      <span className={styles.radio} />
      {(label || subLabel) && (
        <span className={styles.labelContainer}>
          {label && <span className={styles.label}>{label}</span>}
          {subLabel && <span className={styles.subLabel}>{subLabel}</span>}
        </span>
      )}
    </label>
  );
};

export default RadioInput;
