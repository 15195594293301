import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import Clickable from '@uikit/components/Clickable/Clickable';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import Icon from '@uikit/components/Icon/Icon';
import NotificationIcon from '@uikit/components/NotificationIcon/NotificationIcon';
import clsx from 'clsx';
import styles from './PageHeaderAction.module.scss';
import { PageHeaderActionProps } from './types';

const PageHeaderAction = ({
  badgeValue,
  icon,
  isActive = false,
  isInternalUrl = true,
  onClick,
  title,
  url,
}: PageHeaderActionProps) => {
  const isMobile = useIsMobile(BreakPoint.MD);

  const iconElement =
    badgeValue !== undefined ? (
      <NotificationIcon
        aria-hidden="true"
        isOutlined
        notificationCount={badgeValue}
        size={isMobile ? 'iconSize24' : 'iconSize20'}
        variant={icon}
      />
    ) : (
      <Icon
        aria-hidden="true"
        isOutlined
        size={isMobile ? 'iconSize24' : 'iconSize20'}
        variant={icon}
      />
    );

  const content = (
    <>
      {iconElement}
      {title && <span className={styles.title}>{title}</span>}
    </>
  );

  if (!onClick && !url) {
    return <div className={styles.base}>{content}</div>;
  }

  return (
    <Clickable
      className={clsx(styles.base, styles.isInteractive, {
        [styles.isActive]: isActive,
      })}
      {...(!url
        ? {
            actionType: ClickableAction.Custom,
            onClick: onClick!,
          }
        : isInternalUrl
        ? { actionType: ClickableAction.Linkintern, linkintern: url, onClick }
        : { actionType: ClickableAction.Linkextern, linkextern: url, onClick })}
    >
      {content}
    </Clickable>
  );
};

export default PageHeaderAction;
