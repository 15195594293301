import { currentProjectName, ProjectName } from '@/config';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { CSSProperties, useId } from 'react';
import styles from './ProjectLogo.module.scss';
import { ProjectLogoProps, SVGProjectLogoProps } from './types';

const EnercityLogo = dynamic(() => import('./EnercityLogo'));
const EcgLogo = dynamic(() => import('./EcgLogo'));
const EegLogo = dynamic(() => import('./EegLogo'));
const EngLogo = dynamic(() => import('./EngLogo'));
const EsgLogo = dynamic(() => import('./EsgLogo'));
const EsolLogo = dynamic(() => import('./EsolLogo'));
const MeinHGasLogo = dynamic(() => import('./MeinHGasLogo'));

const projectLogos: Record<
  ProjectName,
  React.ComponentType<SVGProjectLogoProps>
> = {
  enercity: EnercityLogo,
  ecg: EcgLogo,
  eeg: EegLogo,
  eng: EngLogo,
  esg: EsgLogo,
  esol: EsolLogo,
  'mein-h-gas': MeinHGasLogo,
};

const projectLogoAspectRatios: Record<ProjectName, number> = {
  enercity: 143 / 34,
  ecg: 143 / 54,
  eeg: 143 / 54,
  eng: 105 / 81,
  esg: 143 / 54,
  esol: 143 / 54,
  'mein-h-gas': 2,
};

const ProjectLogo = ({
  className,
  height,
  id,
  project = currentProjectName,
  solidFill = false,
  width,
}: ProjectLogoProps) => {
  const CurrentLogo = projectLogos[project];
  const style = {
    '--aspect-ratio': projectLogoAspectRatios[project],
  } as CSSProperties;

  const fallbackId = useId();

  return (
    <CurrentLogo
      className={clsx(styles.base, className)}
      height={height}
      id={id ?? fallbackId}
      solidFill={solidFill}
      style={style}
      width={width}
    />
  );
};

export default ProjectLogo;
