import config, { endpoints } from '../config';

export interface ContactFormOptions {
  recipientId: string;
  mailTemplateId: string;
  formName: string;
  buttonLabel?: string;
  keysOrder?: string[];
  frcCaptchaSolution: string;
  useEEGServer?: boolean;
  doubleOptInActive: boolean;
  formApiTarget: string;
}

const projectName = process.env.PROJECT_NAME ?? 'enercity';
const FORM_API_BASE_URL = process.env.FORM_API_BASE_URL;
const SITE_ROOT = process.env.SITE_ROOT || 'http://localhost:3000';

const submitContactForm = async (
  data: { [key: string]: unknown },
  options: ContactFormOptions
): Promise<Response> => {
  if (window.FORM_API_BASE_URL || FORM_API_BASE_URL) {
    const endpoint = `${window.FORM_API_BASE_URL || FORM_API_BASE_URL}/record`;
    const body = JSON.stringify({
      formId: options.formApiTarget,
      formFields: {
        formApiReceiptInnerTemplate: options.mailTemplateId,
        formApiConfirmation: options.doubleOptInActive || false,
        formApiCustomerRecipient: data.mail,
        formApiInternalRecipientId: options.recipientId,
        ...data,
      },
      controlFields: {
        keysOrder: options.keysOrder,
        formName: options.formName,
        frontendBaseUrl: SITE_ROOT,
      },
      frcCaptchaSolution: options.frcCaptchaSolution,
    });

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });
  } else {
    const endpoint = `${config.api.baseUrl}${endpoints.contactForm}`;

    const body = JSON.stringify({
      ...data,
      ...options,
      projectName,
    });

    return fetch(endpoint, {
      method: 'POST',
      body,
    });
  }
};

export default submitContactForm;
