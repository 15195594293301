export enum ContactTelephoneSize {
  Small = 'small',
  Default = '',
}

export enum ContactTelephoneAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
