import type { HeaderLink } from '@/hooks/useHeader/types';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import type { ClickableProps } from '@uikit/components/Clickable/interfaces';

export const headerLinkToClickableProps = (
  link: HeaderLink
): ClickableProps => {
  if (link.url.startsWith('#')) {
    return {
      actionType: ClickableAction.Anchor,
      anchor: link.url.replace('#', ''),
    };
  }
  if (link.isInternalUrl) {
    return {
      actionType: ClickableAction.Linkintern,
      linkintern: link.url,
    };
  }

  return {
    actionType: ClickableAction.Linkextern,
    linkextern: link.url,
  };
};
