import Link from '@uikit/components/Link/Link';
import clsx from 'clsx';
import styles from './PageHeaderTitle.module.scss';
import { PageHeaderTitleProps } from './types';

const PageHeaderTitle = ({
  isInverted = false,
  isLinkEnabled = false,
  title,
  url,
}: PageHeaderTitleProps) => {
  return (
    <div className={clsx(styles.base, { [styles.isInverted]: isInverted })}>
      {!url || !isLinkEnabled ? title : <Link to={url}>{title}</Link>}
    </div>
  );
};

export default PageHeaderTitle;
