import clsx from 'clsx';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import RadioInput from '../RadioInput/RadioInput';
import styles from './FormFieldRadioGroup.module.scss';
import type { FormFieldRadioGroupProps } from './interfaces';

const FormFieldRadioGroup = ({
  items,
  name,
  onChange,
  errorMessage,
  value: currentValue,
  color,
  className,
  label,
  errorMessageHasRelativePosition,
}: FormFieldRadioGroupProps) => {
  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={clsx(styles.base, className)}>
        {items.map(({ label, value, subLabel }) => {
          const checked =
            !!currentValue && [label, value].includes(currentValue);
          return (
            <RadioInput
              color={color}
              key={label || value}
              name={name}
              label={label || value}
              subLabel={subLabel}
              value={value}
              onChange={onChange}
              checked={checked}
              inactive={!checked && !!currentValue}
            />
          );
        })}
      </div>
      {errorMessage && (
        <div className={styles.errorMessageWrapper}>
          <ErrorMessage hasRelativePosition={errorMessageHasRelativePosition}>
            {errorMessage}
          </ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default FormFieldRadioGroup;
