import clsx from 'clsx';
import React, { useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import styles from './CheckBox.module.scss';
import type { CheckBoxProps } from './interfaces';

const CheckBox = ({
  name,
  checked,
  errorMessage,
  defaultChecked,
  disabled,
  onChange,
  onClick,
  inverse,
  id,
  className,
  tabIndex = 0,
  readOnly,
  isIndeterminate = false,
}: CheckBoxProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = isIndeterminate;
    }
  });

  return (
    <div className={clsx(styles.wrapper, className)}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        tabIndex={tabIndex}
        className={styles.input}
        id={id}
        readOnly={readOnly}
        ref={ref}
      />
      <div
        className={clsx(styles.checkbox, {
          [styles.inverse]: inverse,
          [styles.error]: !!errorMessage,
        })}
      >
        <Icon
          size="iconSize32"
          variant={'action/checkmark'}
          className={styles.checkmark}
        />

        <Icon
          size="iconSize32"
          variant={'action/minus'}
          className={styles.minus}
        />
      </div>
    </div>
  );
};

export default CheckBox;
