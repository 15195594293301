import React from 'react';
import Icon from '../Icon/Icon';
import type { NotificationIconProps } from './interfaces';
import styles from './NotificationIcon.module.scss';

const NotificationIcon = ({
  notificationCount,
  ...props
}: NotificationIconProps) => {
  return (
    <div className={styles.base}>
      <Icon {...props} />
      <div className={styles.countNumber}>{notificationCount}</div>
    </div>
  );
};

export default NotificationIcon;
