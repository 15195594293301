import Background from '@uikit/components/Background/Background';
import Badge from '@uikit/components/Badge/Badge';
import { BadgeBreakpoint } from '@uikit/components/Badge/consts';
import clsx from 'clsx';
import React from 'react';
import Container from '../../components/Container/Container';
import { ContainerSize } from '../../components/Container/consts';
import Section from '../../components/Section/Section';
import {
  SECTION_BACKGROUND_COLOR_MAPPER,
  SECTION_BACKGROUND_GRADIENT_MAPPER,
  SectionBackgroundColor,
  SectionVariant,
} from '../../components/Section/consts';
import styles from './HeroArea.module.scss';
import HeroAreaCarousel from './HeroAreaCarousel';
import HeroAreaContent from './HeroAreaContent';
import { HeroSize } from './consts';
import type { HeroAreaProps } from './interfaces';

const HeroArea = ({
  aspectRatios,
  badgeColor,
  badgeimage,
  badgePosition,
  badgetext,
  badgeTextType,
  carouselImages,
  children,
  hasAspectRatioFallback = false,
  image,
  size = HeroSize.Large,
  skewedBottom = true,
  stageBackground = 'image',
  stageBoxClassName,
  vimeoUrl,
  backgroundColor = SectionBackgroundColor.Default,
  videoName,
}: HeroAreaProps) => {
  const showBadge = Boolean(
    badgePosition !== 'hidden' && (badgetext || badgeimage)
  );

  const hasStageBox =
    !(typeof children === 'string') &&
    React.Children.toArray(children).some((child) => {
      if (!(typeof child === 'string')) {
        return (
          (child as any).type.displayName !== HeroAreaContent.name &&
          (child as any).props?.item?.componentName !== 'hero-area-content'
        );
      }
      return false;
    });

  const badgeClassnames = clsx(styles.badge, {
    [styles.badgeBottomRight]: badgePosition !== 'hidden',
  });

  const primaryImage =
    stageBackground === 'image-carousel' &&
    typeof carouselImages !== 'undefined' &&
    carouselImages.length > 0
      ? carouselImages[0]
      : image;

  const showBackgroundColor =
    backgroundColor &&
    backgroundColor !== 'default' &&
    backgroundColor in SECTION_BACKGROUND_COLOR_MAPPER;

  const showBackgroundGradient =
    backgroundColor && backgroundColor in SECTION_BACKGROUND_GRADIENT_MAPPER;

  return (
    <div className={styles.base}>
      {showBackgroundColor && (
        <Background.Color
          color={SECTION_BACKGROUND_COLOR_MAPPER[backgroundColor]}
        />
      )}
      {showBackgroundGradient && (
        <Background.Gradient
          gradient={SECTION_BACKGROUND_GRADIENT_MAPPER[backgroundColor]}
        />
      )}
      <div
        className={clsx(styles.wrapper, {
          [styles.wrapperLarge]: size === HeroSize.Large && hasStageBox,
          [styles.wrapperSmall]: size === HeroSize.Small && hasStageBox,
        })}
      >
        {stageBackground === 'image-carousel' && (
          <HeroAreaCarousel
            images={carouselImages ?? []}
            style={{
              paddingBottom:
                primaryImage && `${primaryImage.calculatedAspectRatio}%`,
            }}
          />
        )}

        {showBadge && (
          <div
            className={clsx(styles.badgeWrapper, {
              [styles.skewedBottom]: skewedBottom,
              [styles.withStageBox]: hasStageBox,
            })}
          >
            <Badge
              image={badgeimage}
              noTail
              className={badgeClassnames}
              color={badgeColor}
              badgeTextType={badgeTextType}
              badgeBreakpoint={BadgeBreakpoint.MD}
              isRectangleOnMobile
            >
              {badgetext}
            </Badge>
          </div>
        )}

        <Section
          aspectRatios={aspectRatios}
          bgImageUrl={primaryImage}
          canSeeThrough={stageBackground === 'image-carousel'}
          className={styles.section}
          hasAspectRatioFallback={hasAspectRatioFallback}
          hasPadding={false}
          noSpaceBetween
          resetTopSpace
          skewedBottom={skewedBottom}
          variant={
            size === HeroSize.Large
              ? SectionVariant.KeyVisual
              : SectionVariant.SmallKeyVisual
          }
          vimeoUrl={vimeoUrl}
          videoName={videoName}
        >
          {!hasStageBox && !!children && (
            <Container
              size={ContainerSize.Small}
              className={styles.titleContainer}
            >
              {children}
            </Container>
          )}
        </Section>
      </div>
      {hasStageBox && (
        <div
          className={clsx(
            styles.stagebox,
            {
              [styles.stageboxInRegular]: size === HeroSize.Large,
              [styles.stageboxInSmall]: size === HeroSize.Small,
            },
            stageBoxClassName
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
export default HeroArea;
