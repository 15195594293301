import styles from './FormRow.module.scss';
import { DisplaySizes } from './interfaces';

export const classNameHelper = (
  property: DisplaySizes,
  propertyname: string
): string[] => {
  return Object.entries(property)
    .filter(([value]) => !!value)
    .map(
      ([key, value]) =>
        styles[
          `${propertyname}${key}${
            value ? value * 1000 : 0
          }` as unknown as keyof typeof styles
        ]
    );
};
