import clsx from 'clsx';
import { WidgetInstance } from 'friendly-challenge';
import { useEffect, useRef } from 'react';
import styles from './FrcCaptchaWidget.module.scss';
import { FrcCaptchaWidgetProps } from './types';

const FRC_CAPTCHA_SITEKEY = process.env.FRC_CAPTCHA_SITEKEY;
const FRC_CAPTCHA_PUZZLE_ENDPOINT = process.env.FRC_CAPTCHA_PUZZLE_ENDPOINT;

export const FrcCaptchaWidget = ({
  className,
  hidden,
  onError,
  onSolved,
  onStarted,
  startMode = 'auto',
  widgetRef,
}: FrcCaptchaWidgetProps) => {
  const container = useRef<HTMLDivElement | null>(null);
  const widgetRefBackup = useRef<WidgetInstance | null>(null);
  const widget = widgetRef ?? widgetRefBackup;

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        doneCallback: onSolved,
        errorCallback: onError,
        startedCallback: onStarted,
        language: 'de',
        puzzleEndpoint: FRC_CAPTCHA_PUZZLE_ENDPOINT,
        sitekey: FRC_CAPTCHA_SITEKEY,
        startMode,
      });
    }

    return () => {
      widget.current?.destroy();
      widget.current = null;
    };
  }, [onError, onSolved, onStarted, startMode, widget]);

  return (
    <div
      ref={container}
      className={clsx('frc-captcha', className, { [styles.hidden]: hidden })}
    />
  );
};
