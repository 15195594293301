import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import clsx from 'clsx';
import styles from './StageBox.module.scss';
import type { StageBoxProps } from './interfaces';

const StageBox = ({
  children,
  backgroundType = 'gradient',
  badgeSlot,
  bgClassName,
  hasShadow = false,
  hasTopLine = false,
  hasMobileBottomSkew = true,
  hasMobileShadow = true,
}: StageBoxProps) => {
  const isMobile = useIsMobile(BreakPoint.XS);

  const baseElement = (
    <article
      className={clsx(styles.base, styles[backgroundType], bgClassName, {
        [styles.hasTopLine]: hasTopLine,
        [styles.hasMobileBottomSkew]: hasMobileBottomSkew,
      })}
    >
      {children}
    </article>
  );

  const hasShadowHost = hasMobileShadow
    ? hasShadow || backgroundType === 'white'
    : !isMobile;

  return (
    <section className={styles.host}>
      {hasShadowHost ? (
        <div className={styles.shadowHost}>{baseElement}</div>
      ) : (
        baseElement
      )}

      {badgeSlot}
    </section>
  );
};

export default StageBox;
