import { DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS } from '@uikit/components/LegacyPicture/consts';
import ImageTransformerPicture from '@uikit/components/LegacyPicture/image-transformer/ImageTransformerPicture';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import styles from './HeroAreaCarousel.module.scss';
import { HeroAreaCarouselProps } from './types';

const HeroAreaCarousel = ({ images, style }: HeroAreaCarouselProps) => {
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setActiveImage((active) =>
          active === images.length - 1 ? 0 : active + 1
        ),
      5000
    );

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className={styles.base} style={style}>
      {images.map(({ metadata, name, path }, index) => (
        <ImageTransformerPicture
          breakpointAspectRatioConfigs={DEFAULT_BREAKPOINT_ASPECT_RATIO_CONFIGS}
          imgProps={{
            alt: metadata?.caption || metadata?.title || name,
            className: clsx(styles.image, {
              [styles.isActive]: activeImage === index,
            }),
            title: metadata?.title,
          }}
          key={path}
          path={path}
        />
      ))}
    </div>
  );
};

export default HeroAreaCarousel;
