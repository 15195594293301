import clsx from 'clsx';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import RichText from '../RichText/RichText';
import styles from './FormRow.module.scss';
import { classNameHelper } from './helpers';
import type { FormRowProps } from './interfaces';

const FormRow = ({
  children,
  fullWidthChildren = true,
  title,
  description,
  className,
  errorMessage,
  columnMarginSize = { default: 0.75 },
  bottomMarginSize = { default: 1, sm: 2 },
}: FormRowProps): JSX.Element => {
  const classes = clsx(
    styles.base,
    className,
    {
      [styles.fullWidthChildren]: fullWidthChildren,
    },
    classNameHelper(columnMarginSize, 'columnMarginSize'),
    classNameHelper(bottomMarginSize, 'bottomMarginSize')
  );

  if (title || description || errorMessage) {
    return (
      <>
        {title && <div className={styles.title}>{title}</div>}
        {description && (
          <RichText className={styles.description}>{description}</RichText>
        )}
        <div className={classes}>{children}</div>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  }

  return <div className={classes}>{children}</div>;
};

export default FormRow;
