import React from 'react';
import styles from './FormInfoBox.module.scss';
import RichText from '../RichText/RichText';
import clsx from 'clsx';

interface FormInfoBoxProps {
  withoutRichText?: boolean;
  children: React.ReactNode;
}

const FormInfoBox = ({
  withoutRichText = false,
  children,
}: FormInfoBoxProps) => {
  const childrenContent = children ?? (
    <p>
      Informationen gemäß Art. 13 DSGVO über die Verarbeitung Ihrer
      personen-bezogenen Daten finden Sie in unseren{' '}
      <a
        target="_blank"
        rel="noopener"
        href="/datenschutz/datenschutzinformationen"
      >
        Datenschutzinformationen
      </a>
      .
    </p>
  );
  const content = (
    <>
      {withoutRichText ? (
        <div className={clsx(styles.text, styles.withOutRichText)}>
          {childrenContent}
        </div>
      ) : (
        <>
          <RichText className={styles.text}>{childrenContent}</RichText>
        </>
      )}
    </>
  );

  return <div className={styles.base}>{content}</div>;
};

export default FormInfoBox;
