import { FrcCaptchaWidget } from '@/components/FrcCaptchaWidget/FrcCaptchaWidget';
import { FrcCaptchaError } from '@/components/FrcCaptchaWidget/types';
import { WidgetInstance } from 'friendly-challenge';
import { useCallback, useRef } from 'react';
import { FrcCaptchaComponentProps } from './types';

const FRC_CAPTCHA_SITEKEY = process.env.FRC_CAPTCHA_SITEKEY;
const FRC_CAPTCHA_PUZZLE_ENDPOINT = process.env.FRC_CAPTCHA_PUZZLE_ENDPOINT;

const useFrcCaptcha = () => {
  const promiseActions = useRef<{
    resolve: (value: string) => void;
    reject: (reason?: any) => void;
  }>();
  const solution = useRef<string | undefined>();
  const isSolving = useRef<boolean>(false);
  const widget = useRef<WidgetInstance | null>(null);

  const handleSolved = useCallback((newSolution: string) => {
    isSolving.current = false;

    if (promiseActions.current) {
      promiseActions.current.resolve(newSolution);
      promiseActions.current = undefined;
      return;
    }

    solution.current = newSolution;
  }, []);

  const handleError = useCallback((error: FrcCaptchaError) => {
    isSolving.current = false;
    promiseActions.current?.reject(error);
  }, []);

  const handleStarted = useCallback(() => {
    isSolving.current = true;
  }, []);

  const getSolution = useCallback(() => {
    return new Promise<string>((resolve, reject) => {
      // missing ENV vars deactivate solving without rejection
      // (infrastructure is causing it -> server has to decide)
      // if frcCaptcha is making problems this can be used to quickly deactivate it
      if (!FRC_CAPTCHA_SITEKEY || !FRC_CAPTCHA_PUZZLE_ENDPOINT) {
        return resolve('');
      }

      if (!widget.current && !solution.current) {
        return reject(
          'no frcCaptcha widget found. Did you forget to render the FrcCaptchaComponent?'
        );
      }

      promiseActions.current?.reject(
        'aborted because a new solution was requested'
      );

      if (solution.current) {
        const resolveValue = solution.current;
        promiseActions.current = undefined;
        solution.current = undefined;
        return resolve(resolveValue);
      }

      if (!isSolving.current) {
        widget.current?.reset();
      }
      promiseActions.current = { resolve, reject };
    });
  }, []);

  const restartSolving = useCallback(() => {
    if (!solution.current && !isSolving.current) {
      widget.current?.reset();
    }
  }, []);

  const FrcCaptchaComponent = useCallback(
    ({ hidden = true }: FrcCaptchaComponentProps) => (
      <FrcCaptchaWidget
        onError={handleError}
        onStarted={handleStarted}
        onSolved={handleSolved}
        widgetRef={widget}
        startMode="auto"
        hidden={hidden}
      />
    ),
    [handleError, handleSolved, handleStarted]
  );

  return {
    /**
     * Captcha Component needs to be rendered in order to successfully call getSolution()!
     * It is hidden by default, though.
     * Puzzle solving starts immediately after component gets rendered.
     */
    FrcCaptchaComponent,
    getSolution,
    /**
     * Background calculations only start automatically before the FIRST call of getSolution().
     * If you need several solutions over time you can restart background calculations with
     * this function before the next getSolution()-call is needed in order to improve performance.
     * This function won't start a new calculation if there's already one going on or if
     * a solution is available.
     */
    restartSolving,
  };
};

export default useFrcCaptcha;
