import clsx from 'clsx';
import parseCustomMarkdown from '../../helpers/parseCustomMarkdown';
import stripHTMLTags from '../../helpers/stripHTMLTags';
import styles from './Badge.module.scss';
import {
  BadgeBreakpoint,
  badgeMobile,
  badgeStyles,
  BadgeTextType,
} from './consts';
import type { BadgeProps } from './types';

const Badge = ({
  children,
  image,
  color,
  badgeTextType = BadgeTextType.Default,
  badgeBreakpoint = BadgeBreakpoint.SM,
  noTail,
  className,
  isRectangleOnMobile = false,
}: BadgeProps) => {
  const colorStyle = color ? styles[color] : '';

  const classes = clsx(
    colorStyle,
    styles.default,
    { [styles.noTail]: noTail, [styles.mobileRectangle]: isRectangleOnMobile },
    badgeStyles[badgeTextType],
    badgeMobile[badgeBreakpoint],
    className
  );

  const pureChildren = stripHTMLTags(children);

  return image ? (
    <img
      draggable={false}
      src={image.path}
      alt={image.name}
      className={className}
    />
  ) : (
    <div className={classes}>
      {pureChildren && (
        <div
          dangerouslySetInnerHTML={{
            __html: parseCustomMarkdown(pureChildren),
          }}
        />
      )}
    </div>
  );
};

export default Badge;
