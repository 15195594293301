import { PREVENT_DISMISS_ATTRIBUTE } from '@/hooks/useHeader/consts';
import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import styles from './PageHeaderActivator.module.scss';
import { PageHeaderActivatorProps } from './types';

const PageHeaderActivator = ({
  children,
  className,
  isActive,
  isEnabled,
  isInverted,
  onClick,
}: PageHeaderActivatorProps) => {
  const Tag = isEnabled ? 'button' : 'div';

  return (
    <Tag
      aria-controls={isEnabled ? 'page-header-dropdown' : undefined}
      aria-expanded={isActive}
      aria-label={
        isEnabled ? (isActive ? 'Menü schließen' : 'Menü öffnen') : undefined
      }
      className={clsx(styles.base, className, {
        [styles.isActive]: isActive,
        [styles.isEnabled]: isEnabled,
        [styles.isInverted]: isInverted,
      })}
      onClick={isEnabled ? onClick : undefined}
      {...{ [PREVENT_DISMISS_ATTRIBUTE]: isEnabled }}
    >
      {children}

      {isEnabled && (
        <Icon
          aria-hidden="true"
          className={styles.chevron}
          size="iconSize20"
          variant="action/chevron-down"
        />
      )}
    </Tag>
  );
};

export default PageHeaderActivator;
