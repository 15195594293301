import { FormFieldUploadFile } from '@uikit/components/FormFieldUpload/interfaces';
import * as Yup from 'yup';

export const zipcode = Yup.string()
  .matches(/^\d{5}$/, 'Die PLZ muss aus fünf Ziffern bestehen.')
  .required('Bitte geben Sie Ihre Postleitzahl an.');

export const firstName = Yup.string()
  .required('Wir benötigen Ihren Vornamen.')
  .min(2, 'Bitte geben Sie Ihren vollständigen Vornamen an.')
  .max(30, 'Ihr Vorname ist leider zu lang.');

export const lastName = Yup.string()
  .required('Wir benötigen Ihren Nachnamen.')
  .min(2, 'Bitte geben Sie Ihren vollständigen Nachnamen an.')
  .max(30, 'Ihr Nachname ist leider zu lang.');

export const streetNumber = Yup.string()
  .required('Wir benötigen Ihre Hausnummer.')
  .matches(/^[a-zA-Z0-9 ]*$/, 'Bitte Angaben überprüfen.');

export const street = Yup.string()
  .required(
    'Wir benötigen Ihre Straße. Bitte wählen Sie einen Vorschlag aus der Liste.'
  )
  .min(2, 'Bitte geben Sie Ihren vollständigen Straßennamen an');

export const city = Yup.string()
  .required('Wir benötigen Ihren Ort.')
  .min(2, 'Bitte geben Sie Ihren vollständigen Ort an.');

export const email = Yup.string()
  .required('Wir benötigen Ihre E-Mail-Adresse.')
  .email('Das ist leider kein gültiges Format für eine E-Mail-Adresse.');

export const emailNotRequired = Yup.string().email(
  'Das ist leider kein gültiges Format für eine E-Mail-Adresse.'
);

export const phone = Yup.string()
  .required('Wir benötigen Ihre Telefonnummer.')
  .transform((value) => value.replace(/[\.\s\-–—()/]/g, ''))
  .matches(/^\+?\d{9,16}$/, {
    message: 'Das ist leider kein gültiges Format für eine Telefonnummer. ',
    excludeEmptyString: false,
  });

export const phoneNotRequired = Yup.string()
  .transform((value) => value.replace(/[\.\s\-–—()/]/gi, ''))
  .matches(/^\+?\d{9,16}$/, {
    message: 'Das ist leider kein gültiges Format für eine Telefonnummer. ',
    excludeEmptyString: true,
  });

export const designation = Yup.string()
  .required('Bitte wählen Sie eine Anrede aus.')
  .oneOf(['Herr', 'Frau', 'Divers'], 'Bitte wählen Sie eine Anrede aus.');

// eslint-disable-next-line
export const message = (min = 5, max = 500) =>
  Yup.string()
    .min(min, 'Ihre Nachricht ist zu kurz.')
    .max(max, `Ihre Nachricht ist zu lang. (Maximal ${max} Zeichen)`);

export const files = Yup.array(
  Yup.object({
    name: Yup.string().matches(/\.pdf$/, 'Nur PDF Dateien'),
    content: Yup.string(),
    size: Yup.number().max(
      5 * 1024 * 1024,
      'Eine der hochgeladenen Dateien ist größer als 5MB.'
    ),
  })
).test(
  '5mb-limit',
  'Sie können leider nicht mehr als 5MB hochladen.',
  // @ts-ignore
  (files: FormFieldUploadFile[]) =>
    files.reduce((previous, { size }) => previous + size, 0) < 5 * 1024 * 1024
);

export const privacyAgreement = Yup.boolean().isTrue(
  'Bitte stimmen Sie der Datenschutzerklärung zu.'
);

export const agreement = Yup.mixed().test(
  'is-true',
  'Wir benötigen Ihre Zustimmung.',
  (value: any) => {
    return value === true;
  }
);

export const iban = Yup.string()
  .required('Wir benötigen eine IBAN.')
  .matches(
    /^DE([0-9a-zA-Z]\s?){20}$/,
    'Die von Ihnen angegebene IBAN ist leider nicht korrekt.'
  );

// eslint-disable-next-line
export const fullName = (message = 'Bitte geben Sie eine korrekten Namen.') =>
  Yup.string().matches(/^[\w]{2,30}\s[\w]{2,30}$/, message);
