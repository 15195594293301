import config from '@/config';
import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { animated, useSpring } from '@react-spring/web';
import toID from '@uikit/helpers/toID';
import clsx from 'clsx';
import { CSSProperties, useId, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import Background from '../Background/Background';
import CookieSettings from '../CookieSettings/CookieSettings';
import Icon from '../Icon/Icon';
import styles from './NewFooter.module.scss';
import {
  NewFooterLinkDirectoryGroupProps,
  NewFooterLinkDirectoryProps,
  NewFooterLinkProps,
  NewFooterLinkRowProps,
  NewFooterProps,
  NewFooterSocialLinkProps,
} from './types';

const ACCORDION_GROUP_PADDING = 16;

const NewFooter = ({
  backgroundSlot = <Background.Gradient />,
  children,
  copyrightHref = '/',
  copyrightText,
  foundationSlot,
  logoSlot,
  skewOverlap = true,
  socialLinksSlot,
}: NewFooterProps) => {
  return (
    <footer className={styles.base}>
      <section
        className={clsx(
          styles.structure,
          !skewOverlap && styles.noNegativeMargin
        )}
      >
        {backgroundSlot}

        <div className={styles.content}>
          <div className={styles.logoArea}>
            <a
              className={clsx('cta_', styles.logoLink)}
              href="/"
              title="Zur Startseite"
            >
              {logoSlot}
            </a>

            {socialLinksSlot && (
              <ul className={styles.links}>{socialLinksSlot}</ul>
            )}
          </div>

          {children}
        </div>
      </section>

      <section className={styles.foundation}>
        <a className={styles.copyright} href={copyrightHref}>
          &copy; {new Date().getFullYear()} {copyrightText}
        </a>

        <ul className={styles.links}>
          {foundationSlot}

          {config.consentManagerId && (
            <li>
              <CookieSettings />
            </li>
          )}
        </ul>
      </section>
    </footer>
  );
};

const Link = ({
  children,
  className,
  isDirectoryTitle = false,
  ...props
}: NewFooterLinkProps) => {
  const content = (
    <a
      className={clsx(
        'cta_',
        isDirectoryTitle && styles.linkDirectoryTitle,
        className
      )}
      {...props}
    >
      {children}
    </a>
  );

  if (isDirectoryTitle) {
    return content;
  }

  return <li>{content}</li>;
};

const LinkDirectory = ({
  children,
  masonry = false,
}: NewFooterLinkDirectoryProps) => {
  return (
    <div className={clsx(styles.linkDirectory, masonry && styles.isMasonry)}>
      {children}
    </div>
  );
};

const LinkDirectoryGroup = ({
  children,
  title,
}: NewFooterLinkDirectoryGroupProps) => {
  const id = useId();
  const [isActive, setIsActive] = useState(false);
  const isMobile = useIsMobile(BreakPoint.SM);
  const { height = 0, ref } = useResizeObserver<HTMLUListElement>();

  const chevronAnimator = useSpring({
    delay: 0,
    config: {
      mass: 1,
      tension: 200,
      friction: 16,
      clamp: true,
    },
    rotate: isActive ? '180deg' : '0deg',
  });

  const listAnimator = useSpring({
    delay: 0,
    config: {
      mass: 1,
      tension: 200,
      friction: 16,
      clamp: !isActive,
    },
    height: isActive ? height + ACCORDION_GROUP_PADDING : 0,
    opacity: isActive ? 1 : 0,
    overflow: 'hidden',
    pointerEvents: (isActive
      ? 'all'
      : 'none') as CSSProperties['pointerEvents'],
  });

  if (!isMobile) {
    return (
      <div className={styles.linkDirectoryGroup}>
        <div className={styles.linkDirectoryTitle}>{title}</div>
        <ul className={styles.links} ref={ref}>
          {children}
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.linkDirectoryGroup}>
      <button
        aria-controls={id}
        aria-expanded={isActive}
        aria-label={`${title}-Menü ${isActive ? 'schließen' : 'öffnen'}`}
        className={styles.linkDirectoryTitle}
        onClick={() => setIsActive(!isActive)}
      >
        <span>{title}</span>
        <animated.div style={chevronAnimator}>
          <Icon
            aria-hidden="true"
            size="iconSize16"
            variant="action/chevron-down"
          />
        </animated.div>
      </button>

      <animated.div style={listAnimator}>
        <ul aria-hidden={!isActive} className={styles.links} id={id} ref={ref}>
          {children}
        </ul>
      </animated.div>
    </div>
  );
};

const LinkRow = ({ linksSlot, socialLinksSlot }: NewFooterLinkRowProps) => {
  return (
    <div className={styles.linkRow}>
      {linksSlot && (
        <ul className={clsx(styles.links, styles.textLinks)}>{linksSlot}</ul>
      )}
      {linksSlot && socialLinksSlot && <div className={styles.divider} />}
      {socialLinksSlot && <ul className={styles.links}>{socialLinksSlot}</ul>}
    </div>
  );
};

const SocialLink = ({ href, title, ...props }: NewFooterSocialLinkProps) => {
  const hasIconVariant = 'iconVariant' in props;
  const children = hasIconVariant ? (
    <Icon size="iconSize24" variant={props.iconVariant} />
  ) : (
    props.children
  );

  return (
    <li>
      <a
        className={clsx('cta_', styles.socialLink, {
          [styles.square]: hasIconVariant,
        })}
        href={href}
        id={`footer-social-${toID(title)}`}
        rel="noreferrer"
        target="_blank"
        title={title}
      >
        {children}
      </a>
    </li>
  );
};

export default Object.assign(NewFooter, {
  Link,
  LinkDirectory,
  LinkDirectoryGroup,
  LinkRow,
  SocialLink,
});
