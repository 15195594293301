import React from 'react';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import styles from './HeroAreaContent.module.scss';
import type { HeroAreaContentProps } from './interfaces';

const HeroAreaContent = ({
  title,
  subtitle,
  children,
  isButtonVisible,
  ...buttonProps
}: HeroAreaContentProps) => {
  return (
    <header className={styles.titleWrapper}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {isButtonVisible && children && (
        <ButtonLink {...buttonProps}>{children}</ButtonLink>
      )}
    </header>
  );
};

export default HeroAreaContent;
