import clsx from 'clsx';
import React from 'react';
import parsePhoneNumber from '../../helpers/parsePhoneNumber';
import { ContactTelephoneSize, ContactTelephoneAlign } from './consts';
import styles from './ContactTelephone.module.scss';
import type { ContactTelephoneProps } from './interfaces';
import RichText from '../RichText/RichText';

const ContactTelephone = ({
  title,
  phone,
  description,
  alignment = ContactTelephoneAlign.Center,
  size = ContactTelephoneSize.Default,
}: ContactTelephoneProps) => {
  return (
    <div
      className={clsx(styles.base, styles[alignment], {
        [styles.small]: size === ContactTelephoneSize.Small,
      })}
    >
      {title && <p className={styles.title}>{title}</p>}
      <p className={styles.telephone}>
        <a
          className={styles.telephoneLink}
          href={`tel:${parsePhoneNumber(phone)}`}
        >
          {phone}
        </a>
      </p>
      {description && (
        <RichText className={styles.description}>{description}</RichText>
      )}
    </div>
  );
};

export default ContactTelephone;
